@import '../mixins';
@import '../color-variables';

.authLayout {
  min-height: 100vh;
  color: $text-main;
  .main {
    width: 480px;
    min-height: 200px;
    border-radius: 16px;
  }
}
