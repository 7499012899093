@import '../mixins';
@import '../color-variables';

.openAICommonContainer {
  
  max-width: 1140px;
  margin: 0 auto;
  color: $text-main;

  .title {
    @include text-header-h3;

    text-align: center;
  }
  .label {
    @include heading-8;
    font-weight: 500;

    text-align: center;
    padding: 8px;
  }
}
