@import '../mixins';
@import '../color-variables';

.title {
  @include text-header-h3;
  color: $text-main;
}

.navbar {
  width: 322px;
}

.containerWrapper {
  .btn_login {
    @include text-small-bold;
    font-weight: 400;

    justify-content: start;
  }
}
