@import './color-variables.scss';

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Thin.ttf');
  font-weight: 100;
}

iframe {
  display: none !important;
}

*::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  cursor: pointer;
}

*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
  background-color: #303757;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 6px;
}
