@import '../mixins';
@import '../color-variables';

.navbar {
  // background-color: $bg2;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  width: 322px;
  padding: 20px 0px;
  border-right: 1px solid $text-main;

  .btnWrapper {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .btn {
    color: $white;
    button {
      background-color: transparent;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      height: 32px;
      align-items: center;
    }
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      background: $primary;
      padding: 4px 8px;
      border-radius: 6px;
    }
  }
  .btn_chat {
    @include text-small-bold;
    font-weight: 400;
    color: $white;

    justify-content: start;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 110;
  background-color: $bg2;
  height: 60px;
  padding: 0px 50px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5);
  .headerLogo {
    height: 50px;
  }

  .info {
    .btn_info {
      color: $white;
      button {
        background-color: transparent;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        height: 32px;
        align-items: center;
      }
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        background: $primary;
        padding: 4px 8px;
        border-radius: 6px;
      }
    }
  }
}

.main {
  flex: 1;
  padding-left: 342px;
  padding-right: 20px;
}
